<template>
  <div class="page-table only-y mainDiv mb-30">
    <div class="card-base baslikBg animated fadeInUp">Günlük Faaliyet Listesi
      <div class="tanimlamaButon">
        <el-tooltip :content='$t("src.views.apps.genel.yeniKaydet")' :open-delay="500" placement="top"
                    v-if="yetkiTuru == 1 || (yetkiTuru == 2 && (yetkiListesi.includes('YK-resmiTatilC100')))">
          <el-button v-on:click="goRoute('Faaliyet Tanımla')" size="mini" type="text"
                     icon="el-icon-plus"/>
        </el-tooltip>
        <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
          <el-button v-on:click="refreshPage()" style="margin-left: 15px !important" type="text"
                     icon="el-icon-refresh"></el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="loading || stateLoading"
         element-loading-text='Faaliyet Listesi Getiriliyor...' element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(255, 255, 255, 1)">
      <el-row class="mt-0 mb-10">
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <div>
            <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                            text-color="gray" :disabled="searchLoading">
            </el-radio-group>
          </div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <el-col :lg="24" :md="24" :sm="24" :xs="24">
            <ul class="islemler" v-if="isDesktop">
              <li class="detay" style="color:#387ADF"
                  v-if="yetkiTuru == 1 || (yetkiTuru == 2 && (yetkiListesi.includes('YK-resmiTatilR100') || yetkiListesi.includes('YK-resmiTatilR200') || (yetkiListesi.includes('YK-resmiTatilU100'))))">
                <i class="mdi mdi-receipt"></i>
                Faaliyet Detayı
              </li>
              <li class="duzenle" style="color:#FF8911"
                  v-if="yetkiTuru == 1 || (yetkiTuru == 2 && (yetkiListesi.includes('YK-resmiTatilR100') || yetkiListesi.includes('YK-resmiTatilR200') || (yetkiListesi.includes('YK-resmiTatilU100'))))">
                <i class="mdi mdi-circle-edit-outline"></i>
                Faaliyeti Güncelle
              </li>
            </ul>
            <ul class="islemler" v-else>
              <li class="detay" style="color:#387ADF"
                  v-if="yetkiTuru == 1 || (yetkiTuru == 2 && (yetkiListesi.includes('YK-resmiTatilR100') || yetkiListesi.includes('YK-resmiTatilR200') || (yetkiListesi.includes('YK-resmiTatilU100'))))">
                <i class="mdi mdi-receipt"></i>
                Faaliyet Detayı
              </li>
              <li class="duzenle" style="color:#FF8911"
                  v-if="yetkiTuru == 1 || (yetkiTuru == 2 && (yetkiListesi.includes('YK-resmiTatilR100') || yetkiListesi.includes('YK-resmiTatilR200') || (yetkiListesi.includes('YK-resmiTatilU100'))))">
                <i class="mdi mdi-circle-edit-outline"></i>
                Faaliyeti Güncelle
              </li>
            </ul>
          </el-col>
        </el-col>
      </el-row>
      <el-table v-loading="searchLoading" border stripe :data="faaliyetList" style="width: 100%"
                :element-loading-text='"Faaliyet Raporu Aranıyor. Lütfen Bekleyin."'
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 1)">
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="faaliyetAra" placeholder='Firma Ara' v-model="firmaAra"
                      v-debounce:500ms="faaliyetAra" size="mini"/>
          </template>
          <el-table-column prop="firmaAdi" label="Firma" min-width="200">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.firmaAdi }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="faaliyetAra" placeholder='Proje Ara' v-model="projeAra"
                      v-debounce:500ms="faaliyetAra" size="mini"/>
          </template>
          <el-table-column prop="proje" label="Proje" min-width="200">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.proje }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="faaliyetAra" placeholder='Başlık Ara' v-model="baslikAra"
                      v-debounce:500ms="faaliyetAra" size="mini"/>
          </template>
          <el-table-column prop="baslik" label="Başlık" min-width="200">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.baslik }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="faaliyetAra" placeholder='Gün Ara' v-model="gunAra"
                      v-debounce:500ms="faaliyetAra" size="mini"/>
          </template>
          <el-table-column prop="gun" label="Gün" width="150">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.gun }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="faaliyetAra" placeholder='Hazırlayan Ara' v-model="hazirlayanAra"
                      v-debounce:500ms="faaliyetAra" size="mini"/>
          </template>
          <el-table-column prop="hazirlayan" label="Hazırlayan" min-width="200">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.hazirlayan }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="faaliyetAra" placeholder='Şantiye Şefi Ara' v-model="sefAra"
                      v-debounce:500ms="faaliyetAra" size="mini"/>
          </template>
          <el-table-column prop="santiyeSefi" label="Şantiye Şefi" min-width="200">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.santiyeSefi }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-date-picker
                @change="faaliyetAra"
                v-on:clear="faaliyetAra"
                clearable
                v-model="tarihAra"
                type="date"
                size="mini"
                placeholder="Tarih Ara"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                style="width: 94%">
            </el-date-picker>
          </template>
          <el-table-column prop="tarih" label="Tarih" min-width="200">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.tarih.slice(0, 16) }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
          </template>
          <el-table-column prop="faaliyetID" label="İşlemler" min-width="100">
            <template slot-scope="props">
              <div style="display: flex;justify-content: end;">
                <el-tooltip class="item" effect="dark" content="Detay" placement="left">
                  <el-button @click="faaliyecAction(props.row.faaliyetID,'detay')" type="text" size="mini">
                    <i class="mdi mdi-receipt" style="color:#387ADF"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Güncelle" placement="right">
                  <el-button @click="faaliyecAction(props.row.faaliyetID,'guncelle')" type="text" size="mini">
                    <i class="mdi mdi-pencil" style="color:#FF8911"/>
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div v-if="faaliyetList.length == []"
           style="width: 100%; height: 50px !important; display: flex; justify-content: center; align-items: center">
        {{ $t("src.views.apps.messages.noData") }}
      </div>
      <el-pagination background layout="sizes,prev, pager, next, jumper, total" :total="total"
                     @size-change="handleSizeChange" @current-change="pageChange" :page-sizes="[10, 20, 50, 100]"
                     :current-page.sync="page" :next-click.sync="page" :prev-click.sync="page" @next-click="pageChange"
                     @prev-click="pageChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import faaliyetService from '../../../WSProvider/FaaliyetService'
import EventBus from '@/components/event-bus'
import notification from '../../../notification'
import functions from '../../../functions'

var moment = require('moment');

export default {
  name: "FaaliyetListesi",
  data() {
    return {
      isDesktop: false,
      loading: false,
      stateLoading: false,
      searchLoading: false,
      //editDialogLoading: false,
      editDialog: false,
      editDialogData: {},
      faaliyetList: [],
      yetkiListesi: [],
      yetkiTuru: "",
      radio: '1',
      rulesMusteri: {},
      sizes: 10,
      selectIcon: '1',
      page: 0,
      postPage: 0,
      total: 4,
      // ?  search keys
      firmaAra: "",
      projeAra: "",
      baslikAra: "",
      tarihAra: "",
      gunAra: "",
      hazirlayanAra: "",
      sefAra: "",
    }
  },
  created() {
    this.checkDevice();
    if (localStorage.getItem("userDataBGSurec")) {
      this.yetkiTuru = this.$store.getters.getYetkiTuru;
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      //console.log(this.yetkiListesi)
    }
  },
  mounted() {
    const self = this
    EventBus.$on("faaliyetList", () => self.getFaaliyetListesi())
    this.getFaaliyetListesi();
    window.addEventListener('resize', this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
  },
  methods: {
    faaliyecAction(faaliyetID, tur) {
      this.$store.commit('setFaaliyetID', faaliyetID)
      if (tur === 'detay') functions.routeSayfa("Faaliyet Detay", this)
      else functions.routeSayfa("Faaliyet Güncelle", this)
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 768;
    },
    faaliyetAra() {
      this.postPage = 0;
      this.page = 1
      if (this.firmaAra.length > 0 || this.projeAra.length > 0 || this.baslikAra.length > 0 || this.tarihAra != null && this.tarihAra.length > 0 || this.gunAra.length > 0 || this.hazirlayanAra.length > 0 || this.sefAra.length > 0) {
        try {
          this.searchLoading = true;
          faaliyetService.faaliyetSearch(this.selectIcon, this.postPage, this.sizes, this.firmaAra, this.projeAra, this.baslikAra, this.tarihAra, this.gunAra, this.hazirlayanAra, this.sefAra).then(response => {
            if (response.status == 200) {
              this.faaliyetList = response.data;
              this.total = response.count
            }
            localStorage.setItem("userDataBGSurec", response.token);
            this.searchLoading = false;
          }).catch(err => {
            if (err.responseJSON) {
              let error = err.responseJSON
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.faaliyetList = []
              }
            }
            this.searchLoading = false;
          })
        } catch (e) {
          // notification.Status("danger", this, "An error occurred during the customer search process.")
          this.searchLoading = false
        }
      } else {
        this.getFaaliyetListesi();
      }
    },
    refreshPage() {
      this.firmaAra = ''
      this.projeAra = ''
      this.baslikAra = ''
      this.gunAra = ''
      this.hazirlayanAra = ''
      this.sefAra = ''
      this.tarihAra = ''
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;
      this.getFaaliyetListesi();
    },
    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },
    getFaaliyetListesi() {
      try {
        this.loading = true;
        faaliyetService.faaliyetListele(this.selectIcon, this.postPage, this.sizes).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          response.data.forEach(function (e) {
            //e.baslamaTarihi = moment(e.baslamaTarihi).format('DD-MM-YYYY');
            e.baslamaTarihi = moment(e.baslamaTarihi).format('DD.MM.YYYY');
            e.bitisTarihi = moment(e.bitisTarihi).format('DD.MM.YYYY');
          })
          if (response.status == 200) {
            this.total = parseInt(response.data.musteriSayisi);
            this.faaliyetList = response.data;
            this.total = parseInt(response.count);
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.faaliyetList = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        this.loading = false
      }
    },
    goRoute(name) {
      functions.routeSayfa(name, this);
    },
    indexMethod(index) {
      return ((this.page - 1) * 10) + index + 1;
    },
    handleSizeChange(val) {
      this.sizes = val;
      this.getFaaliyetListesi();
    },
    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getFaaliyetListesi();
      document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
      this.firmaAra = ""
      this.projeAra = ""
      this.baslikAra = ""
      this.isAdiAra = ""
      this.tarihAra = ""
      this.gunAra = ""
      this.hazirlayanAra = ""
      this.sefAra = ""
    },
    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      this.getFaaliyetListesi();
    },
  }
}

</script>

<style lang="scss">
.el-table .info-row {
  background: rgba(125, 199, 225, 0.45);
}

.el-table .error-row {
  background: rgba(249, 0, 26, 0.08);
}

.el-table .success-row {
  background: rgba(3, 249, 0, 0.08);
}

.el-table .warning-row {
  background: rgba(245, 249, 0, 0.08);
}
</style>
<style lang="scss" scoped>
.refresh {
  color: #fff;
  float: right;
  padding: 0 !important;
  transition: all 0.5s;

}

.refresh:hover {
  transform: rotate(360deg);
}

.el-pagination {
  margin-top: 20px;
  float: right;
}

.el-radio-button--mini .el-radio-button__inner label {
  position: relative;
  bottom: 5px;
}

.kaldirilmis {
  background: #ec205f;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px
}
</style>
<style lang="scss">
.slide-fade-enter-active {
  transition: all .3s ease;
}


@media (min-width: 768px) {
  .el-radio-button--mini .el-radio-button__inner {
    padding: 0 10px !important;
    font-size: 11px;
    border-radius: 0;
  }
}


.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}
</style>